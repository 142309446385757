/* eslint-disable react/jsx-indent */
import React from 'react';
import SEO from './components/SEO';
import CondensedPanel from './components/CondensedPanel';
import Header from './components/Header';
import Footer from './components/Footer';

const Page = ({ children }) => {
    return (
		<>
		<SEO />
		<CondensedPanel />
		<div className="header-and-main">
		  <Header />
		  <main className="main" role="main">
		    {children}
		  </main>
		</div>
		<Footer />
		</>
    );
};

export default Page;
