import React from 'react'
import Page from '../templates/Page'
import SEO from '../templates/components/SEO';
const NotFoundPage = () => (
  <Page>
		  <SEO title="Page not found" />
		  <header className="page-header">
		    <div className="page-header__wrapper">
		      <h1 className="page-header__title">Oh no!</h1>
		    </div>
		  </header>
		  <div className="block block--xl-on-md">
		    <div className="wrapper wrapper--xs">
		      <div className="template-content">
            That page could not be found.
          </div>
		    </div>
		  </div>
  </Page>
)

export default NotFoundPage
